import React from 'react';
import css from './ListingPage.module.css';
function UpperLocation(props) {
  const { publicData, currentListing } = props;
  const { manualAddress, mapLocation, fullManualAddress, location } = publicData;
  // const country = manualAddress
  //   ? fullManualAddress.cityStateCountry
  //     ? `${fullManualAddress.cityStateCountry}`
  //     : ''
  //   : mapLocation?.country
  //   ? `${mapLocation?.country}`
  //   :'';

  const countryFromLocation=location?.address?.split(',')
    ? location?.address?.split(',')[location?.address?.split(',')?.length - 1]
    : location?.address?.split(' ')[location?.address?.split(' ')?.length - 1]
  
  
  const country = manualAddress
    ? fullManualAddress.cityStateCountry
      ? `${fullManualAddress.cityStateCountry}`
      : ''
    : mapLocation?.country
    ? `${mapLocation?.country}`
      : countryFromLocation; 
  
  const state = manualAddress ? null : mapLocation?.state ? `${mapLocation.state},` : '';
  const district = manualAddress ? null : mapLocation?.district ? `${mapLocation.district},` : '';
  const street = manualAddress && fullManualAddress.street ? `${fullManualAddress.street},` : '';
  const zip = manualAddress && fullManualAddress.zip ? `${fullManualAddress.zip}` : '';

  return (
    <div className={css.upperAddress}>
      {manualAddress ? <div>{`${country}`}</div> : <div>{`${district} ${state} ${country}`}</div>}
    </div>
  );
}

export default UpperLocation;

/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;
  const user = ensureCurrentUser(currentUser);

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);

    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const favouriteLink = currentUser?.attributes?.profile?.publicData?.userType === 'customer' && (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('FavouritePage'))}
      name="FavouritePage"
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.favouritesLink" />
      </span>
    </NamedLink>
  );
  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.unAuthenticatedcontentRoot}>
          <div className={css.unAuthenticatedcontent}>
            {/* <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div> */}
            <NamedLink
              name="LandingPage"
              // className={css.signupLink}
              className={classNames(
                css.navigationLink,
                css.landingpageLink,
                currentPageClass('LandingPage')
              )}
            >
              <FormattedMessage id="TopbarMobileMenu.homeLink" />
            </NamedLink>

            <NamedLink
              name="SignupPage"
              // className={css.signupLink}
              className={classNames(css.navigationLink, currentPageClass('SignupPage'))}
            >
              <FormattedMessage id="TopbarMobileMenu.signupLink" />
            </NamedLink>
          </div>
          <div className={css.unAuthenticatedcontentInner}>
            <NamedLink
              name="LoginPage"
              // className={css.loginLink}
              className={classNames(css.navigationLink, currentPageClass('LoginPage'))}
            >
              <FormattedMessage id="TopbarMobileMenu.loginLink" />
            </NamedLink>
            {isAuthenticated ? (
              <NamedLink
                className={classNames(css.navigationLink, currentPageClass('NewListingPage'))}
                name="NewListingPage"
              >
                <FormattedMessage id="TopbarMobileMenu.newListingLink" />
              </NamedLink>
            ) : (
              // <ExternalLink
              //   href="http://www.explore.adventurely.app/apply"
              //   className={classNames(css.navigationLink, currentPageClass('NewListingPage'))}

              // >

              //   <FormattedMessage id="TopbarMobileMenu.newListingLink" />

              // </ExternalLink>

              <a
                href="http://www.explore.adventurely.app/apply"
                className={classNames(css.navigationLink, currentPageClass('NewListingPage'))}
              >
                <span className={css.createListing}>
                  <FormattedMessage id="TopbarMobileMenu.newListingLink" />
                </span>
              </a>
            )}
            {/* <ExternalLink
              className={classNames(css.navigationLink, currentPageClass('BlogPage'))}
              href="https://adventurely.app/blog"
              name="BlogPage"
            >
              <FormattedMessage id="TopbarMobileMenu.blog" />
            </ExternalLink> */}
          </div>
        </div>
        {/* <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div> */}
      </div>
    );
  }

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div
        className={
          currentUser?.attributes?.profile?.publicData?.userType === 'partner'
            ? css.partnerContent
            : css.content
        }
      >
        {/* <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton> */}
        {currentUser?.attributes?.profile?.publicData?.userType === 'customer' && (
          <>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('SearchPage'))}
              name="SearchPage"
            >
              <span className={css.search}>
                <FormattedMessage id="TopbarMobileMenu.searchPageLink" />
              </span>
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('FavouritePage'))}
              name="FavouritePage"
            >
              <span className={css.inbox}>
                <FormattedMessage id="TopbarDesktop.favouritesLink" />
              </span>
            </NamedLink>
          </>
        )}
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{
            tab:
              currentUser?.attributes?.profile?.publicData?.userType === 'partner'
                ? 'sales'
                : 'orders',
          }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {/* {favouriteLink} */}

        {currentUser?.attributes?.profile?.publicData?.userType === 'partner' && (
          <>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('NewListingPage'))}
              name="NewListingPage"
            >
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
            </NamedLink>
          </>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        {currentUser?.attributes?.profile?.publicData?.isSuperAdmin && (
          <>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('CouponCodePage'))}
              name="CouponCodePage"
            >
              <span className={css.inbox}>
                <FormattedMessage id="TopbarDesktop.couponCodeLink" />
              </span>
            </NamedLink>
          </>
        )}
        {/* <ExternalLink
          className={classNames(css.navigationLink, currentPageClass('BlogPage'))}
          href="https://adventurely.app/blog"
          name="BlogPage"
        >
          <FormattedMessage id="TopbarMobileMenu.blog" />
        </ExternalLink> */}
        <div
          className={classNames(css.navigationLink, currentPageClass('Logout'))}
          onClick={onLogout}
        >
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </div>
        {/* <div className={css.spacer} /> */}
      </div>
      {/* <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div> */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;

import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug, parse } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { FaRegHeart } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import { fetchFavListing, removeFav } from '../../containers/FavouritePage/FavouritePage.duck';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LocationName from '../LocationName/LocationName';
import UpperLocation from '../../containers/ListingPage/UpperLocation';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl, forSearchPage, title } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div
      className={classNames(css.price, {
        [css.priceForsearch]: forSearchPage,
      })}
    >
      {!forSearchPage ? (
        <div className={css.priceValue} title={priceTitle}>
          Day Passes from {formattedPrice} / person
        </div>
      ) : (
        <div className={css.priceValue} title={priceTitle}>
          {/* {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })} */}
          Remote Work Day Passes
          <span>
            from <span className={css.priceAmnt}>{formattedPrice} / person</span>
          </span>
        </div>
      )}
      {/* {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null} */}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const history = useHistory();
  const page = parse(history?.location?.search)?.page || 1;
  const dispatch = useDispatch();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    onFavouriteListing,
    favListingIds,
    favInProgress,
    fromFavPage,
    isAuthenticated,
    forSearchPage,
  } = props;
  const [fav, setFav] = useState(false);
  const isCustomer = currentUser?.attributes?.profile?.publicData?.userType === 'customer';
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const isOwnListing = currentListing?.author?.id?.uuid === currentUser?.id?.uuid;
  const id = currentListing?.id?.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author?.attributes?.profile?.publicData?.businessName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const amenities = currentListing?.attributes?.publicData?.amenities;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const handleFav = e => {
    e.preventDefault();
    if (favInProgress) {
      return null;
    } else {
      const body = {
        listingId: currentListing.id.uuid,
        // authorId: currentListing.author.id.uuid,
        // currentUserId: currentUser?.id?.uuid,
        active: currentListing?.attributes?.state === 'published',
      };

      {
        !!fromFavPage
          ? onFavouriteListing(body).then(() => {
              dispatch(removeFav({ listingId: currentListing.id.uuid, config, page }));
            })
          : // .then(() => {
            //   dispatch(fetchFavListing({ config, page }));
            // })
            onFavouriteListing(body);
      }
    }
  };
  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div
          className={classNames(css.priceandheart, {
            [css.locationAndHeart]: forSearchPage,
          })}
        >
          {!forSearchPage ? (
            <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          ) : (
            // <LocationName publicData={publicData} />
            <UpperLocation publicData={publicData} />
          )}
          {isAuthenticated ? (
            <>
              {!isOwnListing && isCustomer && (
                <button
                  type="button"
                  // disabled={favInProgress}
                  className={classNames(css.favIconDiv)}
                  onClick={handleFav}
                >
                  {favListingIds?.includes(id) ? <FaHeart /> : <FaRegHeart />}
                </button>
              )}
            </>
          ) : (
            <NamedLink name="LoginPage">
              <button
                type="button"
                // disabled={favInProgress}
                className={classNames(css.favIconDiv)}
                // onClick={handleFav}
              >
                {/* {favListingIds?.includes(id) ? <FaHeart /> : <FaRegHeart />} */}
                <FaRegHeart />
              </button>
            </NamedLink>
          )}
        </div>

        <div className={css.mainInfo}>
          {showAuthorInfo ? (
            <div
              // className={css.authorInfo}
              className={classNames(css.authorInfo, {
                [css.vendorName]: forSearchPage,
              })}
            >
              <span className={css.pinIcon}>
                <i className="fa-solid fa-location-pin"></i>
              </span>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
          <div className={css.PriceTitleWrap}>
            {/* <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div> */}
            <div className={css.priceDiv}>
              {!!forSearchPage && (
                <div className={css.searchListingPrice}>
                  <PriceMaybe
                    price={price}
                    publicData={publicData}
                    config={config}
                    intl={intl}
                    forSearchPage={true}
                    title={title}
                  />
                </div>
              )}
            </div>
            {amenities !== undefined && amenities?.length > 0 && (
              <div className={css.IncludesDiv}>
                includes{' '}
                {/* {amenities.length > 1
                  ? amenities.slice(0, -1).join(', ') + ' & ' + amenities[amenities.length - 1]
                  : amenities.map(m => m)} */}
                {title.split('+')[1]}
              </div>
            )}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
